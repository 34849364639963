import React from "react";
// material
import { Grid, Stack, Typography, Link, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// routes
import { PATH_PAGE } from "../../../routes/paths";

// ----------------------------------------------------------------------

export default function MainFooter({ type }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={{ backgroundColor: "background.footer", p: 3 }}>
      <Grid item xs={12}>
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems='center'
          justifyContent='center'
          spacing={isMobile ? 3 : 0}
        >
          <Stack
            direction='column'
            sx={{ px: 5, transform: "translateY(-2px)" }}
          >
            <Typography variant='caption' color='grey.50'>
              Realizzato da
            </Typography>
            <img alt='Edra' src={`/images/edra.png`} height={30} />
          </Stack>

          <Link
            color='grey.50'
            underline='none'
            variant='caption'
            to={PATH_PAGE.privacy}
            component={RouterLink}
            sx={{ px: 5 }}
          >
            PRIVACY POLICY
          </Link>

          <Link
            color='grey.50'
            underline='none'
            variant='caption'
            href='javascript:CookieConsent.renew()'
            sx={{ px: 5 }}
          >
            COOKIE
          </Link>

          <Link
            color='grey.50'
            underline='none'
            variant='caption'
            href='https://www.cambialatuarelazioneconlemicrania.it/condizioni-utilizzo/'
            target='_blank'
            sx={{ px: 5 }}
          >
            CONDIZIONI DI UTILIZZO
          </Link>
          <Link
            href='mailto:info@cambialatuarelazioneconemicrania.it'
            color='grey.50'
            underline='none'
            variant='caption'
            sx={{ px: 5 }}
          >
            CONTATTI
          </Link>

          <Link
            href='https://www.lundbeck.com/it'
            target='_blank'
            sx={{ px: 5, transform: "translateY(6px)" }}
          >
            <img alt='Lundbeck' src={`/images/lundbeck.png`} height={50} />
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
}
