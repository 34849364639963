import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../components/layouts/main";
import SurveyEmicrania from "../pages/SurveyEmicrania";
import SurveyDeperessione from "../pages/SurveyDeperessione";
import Reservation from "../pages/Reservation";
import Delete from "../pages/Delete";
import Page404 from "../pages/Page404";
import Privacy from "../pages/Privacy";

// ----------------------------------------------------------------------

const RedirectUrl = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5></h5>;
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          element: (
            <RedirectUrl url="https://www.cambialatuarelazioneconlemicrania.it" />
          ),
          index: true,
        },
        { path: "reservation/:id", element: <Reservation /> },
        { path: "delete/:id", element: <Delete /> },
        { path: "privacy", element: <Privacy /> },
        { path: "404", element: <Page404 /> },
      ],
    },
    {
      path: "emicrania",
      element: <MainLayout type="emicrania" />,
      children: [
        {
          path: "",
          skipLazyLoad: true,
          element: <SurveyEmicrania />,
        },
      ],
    },
    {
      path: "sintomidepressivi",
      element: <MainLayout type="depressione" />,
      children: [
        {
          path: "",
          skipLazyLoad: true,
          element: <SurveyDeperessione />,
        },
      ],
    },
  ]);
}
