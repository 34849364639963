import { useState } from "react";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import moment from "moment";
import axios from "axios";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import arrowBackRounded from "@iconify/icons-material-symbols/arrow-back-rounded";
// material
import {
  Grid,
  Stack,
  Card,
  Typography,
  Button,
  TextField,
  Divider,
  Checkbox,
  Box,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// routes
import { PATH_PAGE } from "../../routes/paths";
// config
import { urlApi } from "../../config";
// store
import useStore from "../../components/store/useStore";

// ----------------------------------------------------------------------

export default function ReservationForm({
  slots,
  setSuccess,
  countSlots,
  setCountSlots,
  userData,
}) {
  const { id } = useParams();
  const theme = useTheme();
  const formReserved = useStore((state) => state.formReserved);
  const navigate = useNavigate();
  const [slotSelected, setSlotSelected] = useState();
  const [error, setError] = useState(false);
  const [errorSlot, setErrorSlot] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: userData && userData.name ? userData.name : "",
      surname: userData && userData.surname ? userData.surname : "",
      email: userData && userData.email ? userData.email : "",
      readPrivacy: false,
    },
  });

  const handleOpen = () => setOpen(true);

  const handleReadPrivacy = () => {
    setValue("readPrivacy", true);
    setOpen(false);
  };

  const onReserve = async (data) => {
    if (!slotSelected && countSlots > 0) {
      setErrorSlot(true);
    } else {
      setErrorSlot(false);

      try {
        const axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.post(
          `${urlApi}reservation/reserve`,
          {
            uniquecode: `${id}`,
            name: `${data.name}`,
            surname: `${data.surname}`,
            email: `${data.email}`,
            slot: `${slotSelected}`,
          },
          axiosConfig
        );

        const result = response.data;

        if (result.success) {
          setSuccess(true);
          formReserved(slotSelected, data.email);
        }

        // if error
        if (!result.success) {
          throw new Error();
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    }
    reset();
  };

  const onWaitinglist = async (data) => {
    try {
      //setCountSlots(0);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${urlApi}reservation/waitinglist`,
        {
          uniquecode: `${id}`,
          name: `${data.name}`,
          surname: `${data.surname}`,
          email: `${data.email}`,
        },
        axiosConfig
      );

      const result = response.data;

      if (result.success) {
        setSuccess(true);
        formReserved(slotSelected, data.email);
      }

      // if error
      if (!result.success) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }

    reset();
  };

  const handleReset = () => {
    setError(false);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mb: 10, pt: 10 }}
      spacing={3}
    >
      {!error ? (
        <>
          <Grid item xs={10} md={8}>
            <Stack direction="row" alignItems="flex-start" spacing={3}>
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={
                  <Icon
                    icon={arrowBackRounded}
                    style={{ color: theme.palette.primary.main }}
                  />
                }
              >
                INDIETRO
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={10} md={8}>
            <Typography variant="h5">
              Inserisci i tuoi dati personali
            </Typography>
            <Typography textAlign="center" variant="caption" sx={{}}>
              * campo obbligatorio
            </Typography>
          </Grid>
          <Grid item xs={10} md={8}>
            <Stack direction="column" spacing={3}>
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Nome*"
                {...register("name", { required: true })}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label="Cognome*"
                {...register("surname", { required: true })}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Email*"
                autoComplete="email"
                type="email"
                variant="outlined"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Inserisci un'email valida",
                  },
                })}
              />
            </Stack>
          </Grid>

          <Grid item xs={10} md={8}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12}>
                <Typography variant="body1" color="black">
                  Seleziona l’orario che preferisci tra quelli disponibili:
                  <br />
                  <strong>Domenica 11 giugno 2023</strong>
                </Typography>
                <Typography textAlign="center" variant="caption" sx={{ pt: 5 }}>
                  Il colloquio avrà una durata di 15 minuti.
                </Typography>
              </Grid>
              {slots.length > 0 ? (
                slots.map((slot, index) => (
                  <Grid item key={index} xs={12}>
                    <Card
                      sx={{
                        px: 5,
                        py: 1,
                        backgroundColor:
                          slotSelected === slot.startingAt
                            ? "select.main"
                            : slot.available > 0
                            ? "white"
                            : "grey",
                        cursor: "pointer",
                        ":hover": {
                          backgroundColor: "select.main", // theme.shadows[20]
                        },
                      }}
                      onClick={() => setSlotSelected(slot.startingAt)}
                    >
                      <Typography
                        textAlign="center"
                        color={
                          slotSelected === slot.startingAt ? "white" : "default"
                        }
                      >
                        {moment(slot.startingAt, "HHmmss").format("HH:mm")}
                      </Typography>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      border: 1,
                      borderColor: "#CAD3E5",
                      height: "60px",
                      backgroundColor: "#EFF3F9",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography textAlign="center" variant="caption">
                      Non ci sono più slot orari disponibili
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  backgroundColor: theme.palette.primary.main,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={10} md={8}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Typography variant="caption">
                <strong>Informativa privacy</strong>
              </Typography>
              <Typography variant="caption">
                Al fine di poter prenotare un appuntamento, è necessario
                prendere visione dell’informativa privacy.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={10} md={8}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box>
                <Checkbox
                  color="primary"
                  {...register("privacy", { required: true })}
                  onClick={handleOpen}
                />
              </Box>
              <Typography variant="caption">
                Ho letto e compreso l’
                <Link
                  to={PATH_PAGE.privacy}
                  component={RouterLink}
                  underline="none"
                  target="_blank"
                >
                  informativa privacy
                </Link>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={10} md={8}>
            {(errors.name ||
              errors.surname ||
              errors.email ||
              errors.privacy) && (
              <Typography color="error" variant="body2">
                Tutti i campi sono necessari
              </Typography>
            )}
            {errorSlot && (
              <Typography color="error" variant="body2">
                Per completare la prenotazione devi selezionare un orario tra
                quelli disponibili
              </Typography>
            )}
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={3}
            >
              {countSlots > 0 ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !isValid || !slotSelected || !watch("readPrivacy")
                    }
                    onClick={handleSubmit(onReserve)}
                    style={{
                      borderRadius: 0,
                      background: "#41748D",
                      border: "1px solid #40738C",
                      boxShadow: "none",
                      flex: "flex: 1 1 0px",

                      padding: "10px 40px",
                    }}
                  >
                    CONFERMA
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isValid || !watch("readPrivacy")}
                  onClick={handleSubmit(onWaitinglist)}
                  style={{
                    borderRadius: 0,
                    background: "#41748D",
                    border: "1px solid #40738C",
                    boxShadow: "none",
                    flex: "flex: 1 1 0px",

                    padding: "10px 40px",
                  }}
                >
                  ISCRIVITI ALLA LISTA D’ATTESA
                </Button>
              )}
            </Stack>
          </Grid>
        </>
      ) : (
        <Grid item xs={10} md={8}>
          <Stack
            direction="column"
            alignItems="center"
            spacing={3}
            sx={{ py: 10 }}
          >
            <Typography variant="h4" color="error" textAlign="center">
              Errore!
              <br />
              <Typography component="span" variant="subtitle2" color="black">
                qualcosa è andato storto
              </Typography>
            </Typography>
            <Button variant="contained" color="inherit" onClick={handleReset}>
              Riprova
            </Button>
          </Stack>
        </Grid>
      )}

      <Dialog open={open}>
        <DialogTitle>Privacy</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Informativa per il trattamento dei dati personali <br />
            In relazione all’evento “Cambia la tua relazione con l’emicrania”
            organizzato da Lundbeck Italia S.p.A. e che si terrà domenica 11
            giugno 2023, presso BAM - Biblioteca degli Alberi, Milano (di
            seguito, l’“Evento”), Lundbeck Italia S.p.A. (di seguito, "Lundbeck"
            o "noi"), in qualità di titolare del trattamento dati, tratterà i
            Suoi dati personali come descritto in ulteriori dettagli in questa
            informativa sulla privacy ed in base alla legislazione applicabile,
            a partire dal Regolamento UE 2016/679 (di seguito, “GDPR”) e dal
            d.lgs. 196/2003, come modificato dal d.lgs. 101/2018 (di seguito,
            “Codice della privacy”).
            <br />
            <br />
            1. Categorie di dati personali trattati <br />
            Raccogliamo direttamente da Lei e trattiamo le seguenti categorie di
            dati personali:
            <br />
            - Dati anagrafici
            <br />
            - Sesso
            <br />
            - Dati di contatto <br />- Dati relativi alla salute da Lei forniti
            volontariamente attraverso la compilazione del questionario
            disponibile sul sito web{" "}
            <Link
              href="https://www.cambialatuarelazioneconlemicrania.it/"
              target="_blank"
              variant="body2"
            >
              www.cambialatuarelazioneconlemicrania.it
            </Link>
            <br />
            <br />
            2. Finalità e base giuridica del trattamento
            <br />
            Trattiamo i Suoi dati personali per i seguenti scopi:
            <br />
            - consentirLe di accedere alla prenotazione di un colloquio gratuito
            con uno specialista presente all’Evento e gestire la Sua
            prenotazione.
            <br />
            <br />
            La base giuridica per la raccolta e il trattamento dei Suoi dati
            personali di cui al precedente punto 1 è:
            <br />• con riferimento alle categorie di dati personali comuni
            (dati anagrafici, sesso, dati di contatto), la necessità di fornirLe
            il servizio richiesto, cfr. art. 6, co. 1, lett. b), GDPR; - con
            riferimento alle categorie di dati personali particolari (dati
            relativi al Suo stato di salute), il Suo consenso, manifestato con
            azione positiva attraverso la compilazione del questionario
            disponibile sul sito web{" "}
            <Link
              href="https://www.cambialatuarelazioneconlemicrania.it/"
              target="_blank"
              variant="body2"
            >
              www.cambialatuarelazioneconlemicrania.it
            </Link>{" "}
            cfr. art. 9, co. 2, lett. a), GDPR.
            <br />
            <br />
            3. Comunicazione dei Suoi dati personali a terzi
            <br />I Suoi dati personali verranno trattati da soggetti terzi
            (quali, ad esempio, l’agenzia che gestisce il sito web{" "}
            <Link
              href="https://www.cambialatuarelazioneconlemicrania.it/"
              target="_blank"
              variant="body2"
            >
              www.cambialatuarelazioneconlemicrania.it
            </Link>
            ), che tratteranno i Suoi dati personali per le finalità di cui alla
            presente informativa, per conto e secondo le indicazioni di
            Lundbeck, in qualità di responsabili del trattamento.
            <br />
            <br />
            4. Periodo di conservazione dei Suoi dati personali <br />
            Conserviamo i suoi dati personali per il tempo necessario a gestire
            la Sua prenotazione di un colloquio gratuito con uno specialista
            presente all’Evento e, in ogni caso, provvederemo alla cancellazione
            degli stessi entro il giorno successivo allo svolgimento
            dell’Evento.
            <br />
            <br />
            5. Conseguenze del mancato conferimento dei dati
            <br />
            Il mancato conferimento dei Suoi dati personali preclude la
            prenotazione di un colloquio gratuito con uno specialista presente
            all’Evento. <br />
            <br />
            6. Trasferimento dei dati verso Paesi extra UE
            <br />
            La gestione e la conservazione dei dati avvengono su server ubicati
            in territorio UE. I dati personali non sono oggetto di trasferimento
            al di fuori dell’Unione europea, quindi verso Paesi terzi od
            organizzazioni internazionali ai sensi del GDPR. In ogni caso,
            Lundbeck Italia S.p.A. garantisce sin d’ora che il trasferimento dei
            dati personali extra UE avverrà nel rispetto delle disposizioni di
            legge applicabili (a partire dal Reg. UE n. 679/2016), stipulando,
            se necessario, accordi che garantiscano un livello di protezione
            adeguato dei dati personali, e/o adottando le clausole contrattuali
            standard previste dalla Commissione europea. Resta ferma la
            richiesta dell’esplicito consenso al trasferimento dei dati per i
            casi nei quali il trasferimento stesso non sia supportato da una
            decisione di adeguatezza della Commissione Europea di cui all’art.
            45 del Regolamento UE 2016/679 o da una delle garanzie adeguate di
            cui all’art. 46 del Regolamento UE 2016/679 già richiamate in parte.
            <br />
            <br />
            7. I Suoi diritti
            <br />
            Fatte salve alcune eccezioni e restrizioni stabilite dalla
            legislazione applicabile, Lei ha diritto di ottenere l'accesso ai
            propri dati personali, nonché la rettifica o la cancellazione degli
            stessi, la limitazione del trattamento, o la portabilità dei Suoi
            dati personali. Lei ha, inoltre, il diritto di opporsi al
            trattamento dei Suoi dati personali.
            <br />
            <br />
            Lei ha il diritto di revocare il consenso in qualsiasi momento,
            senza compromettere la legittimità del trattamento basato sul
            consenso prima della revoca dello stesso. Può revocare il consenso
            inviando una mail a{" "}
            <Link
              href="mailto:Lu-iPrivacy@lundbeck.com"
              target="_blank"
              variant="body2"
            >
              Lu-iPrivacy@lundbeck.com
            </Link>
            .
            <br />
            <br />
            Ha anche il diritto di presentare un reclamo all'autorità di
            controllo competente, che per l’Italia è il Garante per la
            Protezione dei Dati Personali.
            <br />
            <br />
            8. Dettagli di contatto di Lundbeck e del responsabile della
            protezione dei dati (DPO) di Lundbeck
            <br />
            In caso di domande relative alla tutela dei dati personali o se si
            desidera esercitare i propri diritti legali, contattare Lundbeck o
            il responsabile della protezione dei dati di Lundbeck ai seguenti
            contatti:
            <br />
            <br />
            Lundbeck Italia S.p.A.
            <br />
            Via Joe Colombo, 2 - 20124 Milano
            <br />
            Tel 02-677417-1
            <br />
            Email{" "}
            <Link
              href="mailto:Lu-iPrivacy@lundbeck.com"
              target="_blank"
              variant="body2"
            >
              Lu-iPrivacy@lundbeck.com
            </Link>{" "}
            <br />
            <br />
            Responsabile della protezione dei dati di Lundbeck:
            <br />
            E-mail:{" "}
            <Link
              href="mailto:Dataprivacy@Lundbeck.com"
              target="_blank"
              variant="body2"
            >
              Dataprivacy@Lundbeck.com
            </Link>
            <br />
            Il numero di telefono del DPO di Lundbeck può essere consultato su{" "}
            <Link
              href="https://www.Lundbeck.com/"
              target="_blank"
              variant="body2"
            >
              www.Lundbeck.com
            </Link>
            .
            <br />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleReadPrivacy} autoFocus>
            LETTO
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
