import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import { TailSpin } from "react-loader-spinner";
import downloadRounded from "@iconify/icons-material-symbols/download-rounded";
// material
import {
  Grid,
  Stack,
  Box,
  Typography,
  Divider,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// config
import { urlApi } from "../config";
// components
import SurveyComponent from "../components/emicrania/SurveyComponent";
import MessageSuccess from "../components/MessageSuccess";
import MessageError from "../components/MessageError";
import MessageRight from "../components/emicrania/MessageRight";
// store
import useStore from "../components/store/useStore";

// ----------------------------------------------------------------------

export default function SurveyEmicrania() {
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canProceed, setCanProceed] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const emicraniaSurvey = useStore((state) => state.emicraniaSurvey);

  const handleClick = () => {
    window.open(
      "https://www.cambialatuarelazioneconlemicrania.it/wp-content/uploads/2023/05/Diariodellacefalea.pdf",
      "_blank",
      "noreferrer"
    );
  };

  const inizitalize = async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${urlApi}questionnaire/can-proceed`,
        {
          questionnaireid: 1,
        },
        axiosConfig
      );

      const result = response.data;

      if (result.success) {
        setLoading(false);
      }

      // if error
      if (!result.success) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      setCanProceed(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    inizitalize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5} sx={{ pb: 10 }}>
        <MessageRight canProceed={canProceed} />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundImage: isMobile ? `` : `url(/images/background.png)`,
          backgroundPosition: "initial",
          backgroundRepeat: "noRepeat",
          backgroundSize: "cover",
          backgroundColor: isMobile ? "#E8F1F3" : "transparent",
        }}
      >
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 10 }}
          >
            <TailSpin
              height="40"
              width="40"
              color="#334D99"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Stack>
        ) : (
          <>
            {canProceed ? (
              <Box sx={{ pb: 10 }}>
                {!emicraniaSurvey.level && !emicraniaSurvey.code && !error && (
                  <SurveyComponent setError={setError} />
                )}
                {emicraniaSurvey.level && !error && (
                  <MessageSuccess
                    level={emicraniaSurvey.level}
                    code={emicraniaSurvey.code}
                    type={"emicrania"}
                  />
                )}
                {error && <MessageError setError={setError} />}
              </Box>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ py: 10 }}
                spacing={1}
              >
                <Grid item xs={10} md={8}>
                  <Typography variant="h5">
                    Ops.. tutti gli appuntamenti sono stati già prenotati!
                  </Typography>
                </Grid>
                <Grid item xs={10} md={8}>
                  <Typography variant="body2">
                    Purtroppo tutti gli appuntamenti per il consulto con uno
                    specialista sono già stati prenotati. Ti preghiamo di
                    controllare nuovamente il nostro sito nei prossimi giorni
                    per verificare eventuali disponibilità future.
                    <br />
                    <br />
                    Anche se non dovessi riuscire a prenotare un colloquio, ti
                    invitiamo a partecipare al nostro evento che si terrà presso{" "}
                    <strong>
                      BAM - Biblioteca degli Alberi di Milano, il giorno 11
                      giugno dalle ore 17:00.
                    </strong>{" "}
                    Potrai prendere parte a numerose iniziative come workshop,
                    talk, performance artistiche ed eventualmente usufruire
                    anche di un colloquio con uno specialista se dovessero
                    liberarsi posti all’ultimo minuto.
                  </Typography>
                </Grid>
                <Grid item xs={10} md={8}>
                  <Divider
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      height: "2px",
                    }}
                  />
                </Grid>
                <Grid item xs={10} md={8} sx={{ mt: 3 }}>
                  <Typography variant="subtitle1" color="secondary">
                    Monitora
                  </Typography>
                </Grid>
                <Grid item xs={10} md={8}>
                  <Typography variant="subtitle2">
                    Tieni monitorata la frequenza e le caratteristiche del tuo
                    mal di testa per poi confrontarti con il tuo medico curante.
                  </Typography>
                </Grid>
                <Grid item xs={10} md={8}>
                  <Typography variant="body2">
                    <strong>Può esserti di aiuto compilare un diario</strong>.
                    Questo strumento, se compilato con costanza e precisione,
                    rappresenta un valido supporto sia per te sia per il medico
                    poiché consente di tracciare una panoramica completa di
                    tutti gli aspetti che connotano la tua cefalea.
                  </Typography>
                </Grid>
                <Grid item xs={10} md={8}>
                  <Button
                    variant="text"
                    color="secondary"
                    style={{ borderRadius: 0 }}
                    onClick={handleClick}
                    startIcon={
                      <Icon
                        icon={downloadRounded}
                        style={{ color: theme.palette.secondary.main }}
                      />
                    }
                  >
                    SCARICA IL DIARIO
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
