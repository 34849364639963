import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import checkLg from "@iconify/icons-bi/check-lg";
import arrowForwardRounded from "@iconify/icons-material-symbols/arrow-forward-rounded";
import downloadRounded from "@iconify/icons-material-symbols/download-rounded";
import axios from "axios";
// routes
import { PATH_EMICRANIA, PATH_DEPRESSIONE } from "../routes/paths";
// material
import { useTheme } from "@mui/material/styles";
import { Stack, Link, Divider, Grid, Typography, Button } from "@mui/material";

import { urlApi } from "../config";

// ----------------------------------------------------------------------

export default function MessageSuccess({ level, code, type }) {
  const theme = useTheme();

  const handleClick = () => {
    window.open(
      "https://www.cambialatuarelazioneconlemicrania.it/wp-content/uploads/2023/05/Diariodellacefalea.pdf",
      "_blank",
      "noreferrer"
    );
  };

  const handleClickPDF = async () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "questionario";

    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      };
      const response = await axios.post(
        `${urlApi}reservation/pdf`,
        {
          uniquecode: code,
        },
        axiosConfig
      );

      const result = response.data;

      if (true) {
        link.href = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        link.click();
      }

      // if error
      if (!result.success) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 3 }}
        spacing={1}
      >
        <Grid item xs={10} md={8}>
          <Icon
            icon={checkLg}
            height={55}
            width={55}
            style={{ color: theme.palette.error.main }}
          />
        </Grid>
        <Grid item xs={10} md={8}>
          <Typography variant="h5">
            Questionario completato: scaricalo e portalo con te al colloquio con
            l’esperto!
          </Typography>
        </Grid>
        <Grid item xs={10} md={8}>
          <Typography variant="body2">
            {type === "emicrania"
              ? "Prenota ora il tuo colloquio con un neurologo per l’"
              : "Prenota ora il tuo colloquio con uno specialista dei disturbi dell’umore per l’"}
            <strong>11 giugno</strong> presso la{" "}
            <strong>
              Fondazione Riccardo Catella, all’interno del parco BAM -
              Biblioteca degli Alberi, in Via Gaetano de Castillia, 28 - Milano
            </strong>
            . Seleziona subito l’orario che preferisci prima che si esauriscano
            i posti.
          </Typography>
        </Grid>
        <Grid item xs={10} md={8}>
          <Stack
            direction="row"
            alignItems="flex-start"
            spacing={2}
            sx={{ py: 3 }}
          >
            <Button
              variant="contained"
              color="info"
              style={{
                borderRadius: 0,
                flex: "flex: 1 1 0px",
                width: "50%",
                padding: "10px",
                boxShadow: "none",
                minHeight: "24px",
                border: "1px solid #ffffff",
              }}
              onClick={handleClickPDF}
              startIcon={
                <Icon
                  icon={downloadRounded}
                  style={{ color: theme.palette.info.contrastText }}
                />
              }
            >
              SCARICA IL QUESTIONARIO
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                borderRadius: 0,
                background: "#41748D",
                border: "1px solid #40738C",
                boxShadow: "none",
                flex: "flex: 1 1 0px",
                width: "50%",
                padding: "10px",
              }}
              component={RouterLink}
              to={`/reservation/${code}`}
            >
              PRENOTA
            </Button>
          </Stack>
        </Grid>

        {type === "emicrania" && (
          <>
            <Grid item xs={10} md={8}>
              <Divider
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: "2px",
                }}
              />
            </Grid>
            <Grid item xs={10} md={8} sx={{ mt: 3 }}>
              <Typography variant="subtitle1" color="secondary">
                Monitora
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Typography variant="subtitle2">
                Tieni monitorata la frequenza e le caratteristiche del tuo mal
                di testa per poi confrontarti con il tuo medico curante.
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Typography variant="body2">
                <strong>Può esserti di aiuto compilare un diario</strong>.
                Questo strumento, se compilato con costanza e precisione,
                rappresenta un valido supporto sia per te sia per il medico
                poiché consente di tracciare una panoramica completa di tutti
                gli aspetti che connotano la tua cefalea.
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Button
                variant="text"
                color="secondary"
                style={{ borderRadius: 0 }}
                onClick={handleClick}
                startIcon={
                  <Icon
                    icon={downloadRounded}
                    style={{ color: theme.palette.secondary.main }}
                  />
                }
              >
                SCARICA IL DIARIO
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={10} md={8}>
          <Divider sx={{ backgroundColor: theme.palette.primary.main }} />
        </Grid>
        {type === "emicrania" ? (
          <>
            <Grid item xs={10} md={8} sx={{ mt: 3 }}>
              <Typography variant="subtitle1" color="secondary">
                Sapevi che..
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Typography variant="body2">
                Le persone che vivono con emicrania possono soffrire anche di
                disturbi dell’umore
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Typography variant="subtitle2">
                Vuoi compilare il questionario sui sintomi dell’umore?
              </Typography>
            </Grid>
            <Grid item xs={10} md={8}>
              <Link
                component={RouterLink}
                to={PATH_DEPRESSIONE.survey}
                target="_blank"
              >
                <Button
                  variant="text"
                  color="secondary"
                  style={{ borderRadius: 0 }}
                  // component={RouterLink}
                  // to={PATH_DEPRESSIONE.survey}
                  endIcon={
                    <Icon
                      icon={arrowForwardRounded}
                      style={{ color: theme.palette.secondary.main }}
                    />
                  }
                >
                  VAI AL QUESTIONARIO SINTOMI DELL’UMORE
                </Button>
              </Link>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={10} md={8}>
              <Typography variant="subtitle2">
                Vuoi compilare il questionario emicrania?
              </Typography>
            </Grid>

            <Grid item xs={10} md={8}>
              <Link
                component={RouterLink}
                to={PATH_EMICRANIA.survey}
                target="_blank"
              >
                <Button
                  variant="text"
                  color="secondary"
                  style={{ borderRadius: 0 }}
                  // component={RouterLink}
                  // to={PATH_EMICRANIA.survey}
                  endIcon={
                    <Icon
                      icon={arrowForwardRounded}
                      style={{ color: theme.palette.secondary.main }}
                    />
                  }
                >
                  VAI AL QUESTIONARIO EMICRANIA
                </Button>
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
